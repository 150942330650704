import React from 'react';
import PositionCard from './PositionCard';

const OpenPositions = () => {
  const positions = [
    {
      title: 'Frontend Developer',
      location: 'Remote',
      type: 'Full-time',
      description: 'We are looking for a skilled Frontend Developer to join our team and help us build amazing web applications.',
    },
    {
      title: 'Backend Developer',
      location: 'Remote',
      type: 'Full-time',
      description: 'Join our backend team to work on scalable and secure web services for our clients.',
    },
    {
      title: 'UI/UX designer',
      location: 'Remote',
      type: 'Part-time',
      description: 'We need a creative UI/UX designer to enhance the user experience for our digital products.',
    }
  ];

  return (
    <section className="py-16 bg-gray-950">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-6 text-gray-300">Open Positions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-gray-400">
          {positions.map((position, index) => (
            <PositionCard key={index} {...position} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OpenPositions;
