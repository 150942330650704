import React from 'react';
import ServiceOverviewSection from './ServiceOverviewSection';
import ServiceBenefitsSection from './ServiceBenefitsSection';
import ServiceProcessSection from './ServiceProcessSection';
import ServiceTestimonialsSection from './ServiceTestimonialsSection';
import SEOBreadcrumb from './SEOBreadcrumb';

const SEO = () => {
  return (
    <div className="bg-gray-950 text-white">
      <SEOBreadcrumb/>
      <ServiceOverviewSection />
      <ServiceBenefitsSection />
      <ServiceProcessSection />
      <ServiceTestimonialsSection />
    </div>
  );
};

export default SEO;
