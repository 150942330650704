import React from 'react';

const PositionCard = ({ title, location, type, description }) => {
  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg hover:scale-105 transition-transform duration-300">
      <h3 className="text-2xl font-semibold mb-2">{title}</h3>
      <p className="text-sm text-gray-400">{location} - {type}</p>
      <p className="mt-4 text-gray-300">{description}</p>
      <a href="https://wa.link/d7hs70" target='_blank'><button className="mt-6 px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md transition-colors">
        Apply Now
      </button>
      </a>
    </div>
  );
};

export default PositionCard;
