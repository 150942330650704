import React from 'react';
import { motion } from 'framer-motion';

const benefits = [
  { title: 'Keyword Research', description: 'Your website will look great on any device.' },
  { title: 'On-Page SEO', description: 'We ensure your website is optimized for search engines from the start.' },
  { title: 'Off-Page SEO', description: 'We develop websites tailored to your specific needs.' },
  { title: 'Technical SEO', description: 'Our designs are optimized for fast loading times, improving user experience.' }
];

const ServiceBenefitsSection = () => {
  return (
    <section className="py-16 bg-gray-900 text-center text-white">
      <h2 className="text-3xl font-bold mb-8">Benefits of Our Web Design & Development</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {benefits.map((benefit, index) => (
          <motion.div
            key={index}
            className="p-6 bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-all"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-2xl font-semibold">{benefit.title}</h3>
            <p className="mt-4 text-gray-400">{benefit.description}</p>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default ServiceBenefitsSection;
