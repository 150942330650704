import React from 'react';

const ServiceOverviewSection = () => {
  return (
    <section className="py-16 bg-gray-950 text-center text-white">
      <h1 className="text-4xl font-bold mb-4">Expert Guidance for Your Digital Projects</h1>
      <p className="max-w-3xl mx-auto text-lg text-gray-400">
      Our experienced consultants provide strategic guidance to help you make informed decisions about your web development projects. We offer tailored solutions to meet your specific business needs.
      </p>
    </section>
  );
};

export default ServiceOverviewSection;
