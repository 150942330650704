import React from "react";
import { DocumentSearchIcon, PencilAltIcon, DesktopComputerIcon, CogIcon, ClipboardCheckIcon, RocketLaunchIcon, HeadsetIcon } from '@heroicons/react/outline';

export default function AgileMethodology() {
  return (
    <section className="bg-gray-950 py-12 md:py-16">
      <div className="container mx-auto text-center px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-8 md:mb-12">
          <span className="text-gray-200">We </span>
          <span className="text-gray-300">follow</span>{" "}
          <span className="text-gray-200">Agile</span>{" "}
          <span className="text-gray-300">Methodology</span>
        </h2>

        {/* Grid Container */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-7 gap-8 items-center">

          {/* Step 1 */}
          <div className="flex flex-col items-center text-gray-300">
            <div className="p-4 rounded-full mb-4 text-4xl bg-gray-800 hover:bg-gray-500">
              <span className="hover:text-gray-500">🔍</span>
            </div>
            <h3 className="text-lg md:text-xl font-semibold">Analyze</h3>
          </div>

          {/* Step 2 */}
          <div className="flex flex-col items-center text-gray-300">
            <div className="p-4 rounded-full mb-4 text-4xl bg-gray-800 hover:bg-gray-500">
              <span>📝</span>
            </div>
            <h3 className="text-lg md:text-xl font-semibold">Plan</h3>
          </div>

          {/* Step 3 */}
          <div className="flex flex-col items-center text-gray-300">
            <div className="p-4 rounded-full mb-4 text-4xl bg-gray-800 hover:bg-gray-500">
              <span>💻</span>
            </div>
            <h3 className="text-lg md:text-xl font-semibold">Design</h3>
          </div>

          {/* Step 4 */}
          <div className="flex flex-col items-center text-gray-300">
            <div className="p-4 rounded-full mb-4 text-4xl bg-gray-800 hover:bg-gray-500">
              <span>⚙️</span>
            </div>
            <h3 className="text-lg md:text-xl font-semibold">Development</h3>
          </div>

          {/* Step 5 */}
          <div className="flex flex-col items-center text-gray-300">
            <div className="p-4 rounded-full mb-4 text-4xl bg-gray-800 hover:bg-gray-500">
              <span>✔️</span>
            </div>
            <h3 className="text-lg md:text-xl font-semibold">Testing</h3>
          </div>

          {/* Step 6 */}
          <div className="flex flex-col items-center text-gray-300">
            <div className="p-4 rounded-full mb-4 text-4xl bg-gray-800 hover:bg-gray-500">
              <span>🚀</span>
            </div>
            <h3 className="text-lg md:text-xl font-semibold">Launch</h3>
          </div>

          {/* Step 7 */}
          <div className="flex flex-col items-center text-gray-300">
            <div className="p-4 rounded-full mb-4 text-4xl bg-gray-800 hover:bg-gray-500">
              <span>🎧</span>
            </div>
            <h3 className="text-lg md:text-xl font-semibold">Support</h3>
          </div>

        </div>
      </div>
    </section>
  );
}
