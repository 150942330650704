import React from 'react'
import ServicesHeader from './ServicesHeader'
import CallToAction from './CallToAction'
import ServicesList from './ServicesList'
import ServiceBreadcrumb from './ServiceBreadcrumb'

const ServicesPage = () => {
  return (
    <div>
      <ServiceBreadcrumb />
      <ServicesHeader />
      <ServicesList/>
      <CallToAction />
      Services
    </div>
  )
}

export default ServicesPage