import React from "react";
import { Link } from "react-router-dom";

const CookiePolicy = () => {
  const para = "text-xl py-2";
  const head2 = "text-blue-300 text-2xl py-10 text-center";
  const head3 = "text-blue-200 text-2xl py-6 ";
  const head4 = "text-blue-100 text-2xl py-4 ";
  const ulist = "list-disc py-3 pl-8 px-3";
  const list = "py-2";
  const ulp = "";
  const ulpstrong = "font-bold text-blue-100";

  return (
    <div className="bg-gray-950 text-white min-h-screen py-6">
      <div className="max-w-7xl mx-auto">
        <div className="mx-3 text-justify">
        <h1 className='mt-16 py-8 text-blue-600 text-center text-5xl font-extrabold'>Cookie Policy</h1>

          <p className="text-center pb-5">Last updated on October 12, 2024</p>
          <p className={para}>
            This Cookies Policy explains what Cookies are and how We use them.
            You should read this policy so You can understand what type of
            cookies We use, or the information We collect using Cookies and how
            that information is used. This Cookies Policy has been created with
            the help of the{" "}
            <a
              href="https://www.termsfeed.com/cookies-policy-generator/"
              target="_blank"
            >
              Cookies Policy Generator
            </a>
            .
          </p>
          <p className={para}>
            Cookies do not typically contain any information that personally
            identifies a user, but personal information that we store about You
            may be linked to the information stored in and obtained from
            Cookies. For further information on how We use, store and keep your
            personal data secure, see our Privacy Policy.
          </p>
          <p className={para}>
            We do not store sensitive personal information, such as mailing
            addresses, account passwords, etc. in the Cookies We use.
          </p>
          <h2 className={head2}>Interpretation and Definitions</h2>
          <h3 className={head3}>Interpretation</h3>
          <p className={para}>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h3 className={head3}>Definitions</h3>
          <p className={para}>For the purposes of this Cookies Policy:</p>
          <ul className={ulist}>
            <li className={list}>
              <strong className={ulpstrong}>Company</strong> (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
              in this Cookies Policy) refers to LKProfessionals, 6/7, Vidhan's
              Lane, Eachchamoddai, Jaffna - 40000.
            </li>
            <li className={list}>
              <strong className={ulpstrong}>Cookies</strong> means small files that are placed on Your
              computer, mobile device or any other device by a website,
              containing details of your browsing history on that website among
              its many uses.
            </li>
            <li className={list}>
              <strong className={ulpstrong}>Website</strong> refers to LKProfessionals, accessible
              from{" "}
              <a
                href="http://lkprofessionals.com/"
                rel="external nofollow noopener"
                target="_blank"
              >
                http://lkprofessionals.com/
              </a>
            </li>
            <li className={list}>
              <strong className={ulpstrong}>You</strong> means the individual accessing or using the
              Website, or a company, or any legal entity on behalf of which such
              individual is accessing or using the Website, as applicable.
            </li>
          </ul>
          <h2 className={head2}>The use of the Cookies</h2>
          <h3 className={head3}>Type of Cookies We Use</h3>
          <p className={para}>
            Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
            Cookies. Persistent Cookies remain on your personal computer or
            mobile device when You go offline, while Session Cookies are deleted
            as soon as You close your web browser.
          </p>
          <p className={para}>
            We use both session and persistent Cookies for the purposes set out
            below:
          </p>
          <ul className={ulist}>
            <li className={list}>
              <p className={para}>
                <strong className={ulpstrong}>Necessary / Essential Cookies</strong>
              </p>
              <p className={para}>Type: Session Cookies</p>
              <p className={para}>Administered by: Us</p>
              <p className={para}>
                Purpose: These Cookies are essential to provide You with
                services available through the Website and to enable You to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these Cookies,
                the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.
              </p>
            </li>
            <li className={list}>
              <p className={para}>
                <strong className={ulpstrong}>Functionality Cookies</strong>
              </p>
              <p className={para}>Type: Persistent Cookies</p>
              <p className={para}>Administered by: Us</p>
              <p className={para}>
                Purpose: These Cookies allow us to remember choices You make
                when You use the Website, such as remembering your login details
                or language preference. The purpose of these Cookies is to
                provide You with a more personal experience and to avoid You
                having to re-enter your preferences every time You use the
                Website.
              </p>
            </li>
          </ul>
          <h3 className={head3}>Your Choices Regarding Cookies</h3>
          <p className={para}>
            If You prefer to avoid the use of Cookies on the Website, first You
            must disable the use of Cookies in your browser and then delete the
            Cookies saved in your browser associated with this website. You may
            use this option for preventing the use of Cookies at any time.
          </p>
          <p className={para}>
            If You do not accept Our Cookies, You may experience some
            inconvenience in your use of the Website and some features may not
            function properly.
          </p>
          <p className={para}>
            If You'd like to delete Cookies or instruct your web browser to
            delete or refuse Cookies, please visit the help pages of your web
            browser.
          </p>
          <ul className={ulist}>
            <li className={list}>
              <p className={para}>
                For the Chrome web browser, please visit this page from Google:{" "}
                <a
                  href="https://support.google.com/accounts/answer/32050"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  https://support.google.com/accounts/answer/32050
                </a>
              </p>
            </li>
            <li className={list}>
              <p className={para}>
                For the Internet Explorer web browser, please visit this page
                from Microsoft:{" "}
                <a
                  href="http://support.microsoft.com/kb/278835"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  http://support.microsoft.com/kb/278835
                </a>
              </p>
            </li>
            <li className={list}>
              <p className={para}>
                For the Firefox web browser, please visit this page from
                Mozilla:{" "}
                <a
                  href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
                </a>
              </p>
            </li>
            <li className={list}>
              <p className={para}>
                For the Safari web browser, please visit this page from Apple:{" "}
                <a
                  href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                </a>
              </p>
            </li>
          </ul>
          <p className={para}>
            For any other web browser, please visit your web browser's official
            web pages.
          </p>
          <h3 className={head3}>More Information about Cookies</h3>
          <p className={para}>
            You can learn more about cookies here:{" "}
            <a href="https://www.termsfeed.com/blog/cookies/" target="_blank">
              All About Cookies by TermsFeed
            </a>
            .
          </p>
          <h3 className={head3}>Contact Us</h3>
          <p className={para}>
            If you have any questions about this Cookies Policy, You can contact
            us:
          </p>
          <ul className={ulist}>
            <li className={list}>By email: info@lkprofessionals.com</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
