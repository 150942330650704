import { motion } from 'framer-motion';

const ServiceCard = ({ title, description, Icon }) => (
  <motion.div
    className="bg-neutral-900 rounded-xl p-6 shadow-md hover:shadow-lg transition-shadow duration-300 hover:bg-opacity-80"
    whileHover={{ scale: 1.05 }}
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="text-white flex items-center mb-4">
      <Icon className="w-10 h-10 text-blue-400 mr-4" />
      <h3 className="text-xl font-semibold">{title}</h3>
    </div>
    <p className="text-neutral-400">{description}</p>
  </motion.div>
);

export default ServiceCard;
