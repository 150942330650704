import React from 'react';
import LKProfessionalsLogo from '../../assets/logo.png'

const LogoFooter = () => {
  return (
    <div className='size-32 mt-8'>
        <img src={LKProfessionalsLogo} alt="LKProfessionals-logo" />
    </div>
  )
}

export default LogoFooter