import {Link} from 'react-router-dom';
import ServiceCard from "./ServiceCard";
import { FaHandshake, FaCode, FaDatabase, FaMobileAlt, FaTools, FaGlobe, FaChartLine } from "react-icons/fa";

const services = [
  {
    title: "Web Design",
    description: "Crafting stunning, user-centric websites that leave a lasting impression.",
    Icon: FaCode,
    href: "/services/website-design-and-development"
  },
  {
    title: "Web Development",
    description: "Building robust, scalable, and high-performing web applications.",
    Icon: FaDatabase,
    href: "/services/web-app-development"
  },
  {
    title: "Mobile App Development",
    description: "Developing innovative mobile apps that deliver seamless user experiences.",
    Icon: FaMobileAlt,
    href: "/services/mobile-application-development"
  },
  {
    title: "Web Development Consulting",
    description: "Providing expert guidance and strategic solutions for your web development needs.",
    Icon: FaHandshake,
    href: "/services/web-development-consulting"
  },
  {
    title: "SEO",
    description: "Boosting your online visibility and driving organic traffic to your website.",
    Icon: FaChartLine,
    href: "/services/search-engine-opmization"
  },
  {
    title: "Digital Marketing",
    description: "Expanding your digital footprint and connecting with your target audience.",
    Icon: FaGlobe,
    href: "/services/digital-marketing"
  },
  {
    title: "Maintenance & Support",
    description: "Ensuring your website and applications are secure, reliable, and up-to-date.",
    Icon: FaTools,
    href: "/services/maintenance-and-support"
  },
];

const ServicesList = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
      {services.map((service, index) => (
        <Link 
          to={service.href}
          key={index}
          className="transform transition-transform duration-300 hover:scale-105"
        >
          <ServiceCard
            title={service.title}
            description={service.description}
            Icon={service.Icon}
          />
        </Link>
      ))}
    </div>
  );
};

export default ServicesList;