import React from 'react';
import { Phone, Mail, MapPin } from 'lucide-react';

const ContactDetails = () => {
  const contactDetails = [
    { icon: <Phone size={20} />, text: '+94 (76) 123-4321', label: 'Phone' },
    { icon: <Mail size={20} />, text: 'info@lkprofessionals.com', label: 'Email' },
    { icon: <MapPin size={20} />, text: `6/7, Vidhan's Lane, Eachchamoddai, Jaffna - 40000, Sri Lanka`, label: 'Address' }
  ];

  return (
    <div className="bg-gray-950 text-white p-6 rounded-lg shadow-md mb-8">
      <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
      <div className="space-y-4">
        {contactDetails.map((detail, index) => (
          <div key={index} className="flex items-center">
            <div className="w-10 h-10 rounded-full bg-blue-950 hover:bg-blue-950 flex items-center justify-center mr-4">
              {detail.icon}
            </div>
            <div>
              <p className="text-sm text-gray-500">{detail.label}</p>
              <p className="font-medium">{detail.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactDetails;