import React from 'react';
import { ChevronRight, Home } from 'lucide-react';

const PortfolioBreadcrumb = () => {
  const items = ['Home', 'Portfolio', ];
  const pageName = "Welcome to LKProfessionals";
  
  return (
    <div className="w-full overflow-x-auto">
    <h1 className='mt-16 py-8 text-blue-600 text-center text-5xl font-extrabold'>Our Portfolio</h1>
      <nav className="flex items-center justify-between p-4 bg-gradient-to-r from-blue-950 via-black to-gray-950 bg-[length:200%_100%] animate-gradient">
        <ol className="flex items-center space-x-2 text-white">
          {items.map((item, index) => (
            <li key={item} className="flex items-center whitespace-nowrap">
              {index === 0 ? (
                <Home className="w-4 h-4 shrink-0" />
              ) : (
                <ChevronRight className="w-4 h-4 mx-2 shrink-0" />
              )}
              <span className={`
                ${index === items.length - 1 
                  ? 'font-semibold' 
                  : 'opacity-80 hover:opacity-100 transition-opacity'}
              `}>
                {item}
              </span>
            </li>
          ))}
        </ol>
        
        <div className="hidden md:block pl-4 ml-4 border-l border-white/30">
          <h1 className="text-white text-3xl font-bold whitespace-nowrap">{pageName}</h1>
        </div>
      </nav>
    </div>
  );
};

// Add the animation to the global CSS
const style = document.createElement('style');
style.textContent = `
  @keyframes gradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .animate-gradient {
    animation: gradient 3s ease infinite;
  }
`;
document.head.appendChild(style);

export default PortfolioBreadcrumb;