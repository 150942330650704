import React from 'react';

const WhyJoinUs = () => {
  return (
    <section className="py-16 bg-gray-950">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-6 text-gray-300">Why Join Us?</h2>
        <p className="text-gray-400 mb-10 max-w-xl mx-auto">
        LKProfessionals is a new-age Digital Transformation Company working with many international clients across the globe. Our goal is to deliver an unparalleled advantage to our clients through outstanding talent driven by high commitment, energy and ambition.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="p-6 bg-gray-800 rounded-lg">
            <h3 className="text-2xl font-semibold">Innovative Environment</h3>
            <p className="mt-4 text-gray-400">We encourage innovation and creativity in every project.</p>
          </div>
          <div className="p-6 bg-gray-800 rounded-lg">
            <h3 className="text-2xl font-semibold">Professional Growth</h3>
            <p className="mt-4 text-gray-400">We provide ample opportunities for learning and career advancement.</p>
          </div>
          <div className="p-6 bg-gray-800 rounded-lg">
            <h3 className="text-2xl font-semibold">Flexible Work Hours</h3>
            <p className="mt-4 text-gray-400">We offer flexible working hours to accommodate your lifestyle.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyJoinUs;
