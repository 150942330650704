import React from 'react';
import MissionPng from '../../assets/Mission.png'


export default function MissionSection() {
  return (
    <section className="bg-black py-16">
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        {/* Left Side - Mission Image */}
        <div className="md:w-1/2 px-4 md:px-8 mb-8 md:mb-0">
          {/* Replace with your actual image path */}
          <img src={MissionPng} alt="Mission Illustration" className="mx-auto max-w-sm" />
        </div>

        {/* Right Side - Mission Text */}
        <div className="text-white md:w-1/2 px-4 md:px-8">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-blue-500">
            OUR MISSION
          </h2>
          <p className="text-lg mb-6">
            To become the worldwide leading software development partner and help businesses with cutting-edge software solutions using established and newly evolving technologies in the Information technology horizon.
          </p>

          {/* Mission Goals List */}
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="mr-2 text-red-500">🎯</span>
              <span>To stand out unique in the Global MarketPlace.</span>
            </li>
            <li className="flex items-start">
              <span className="mr-2 text-red-500">🎯</span>
              <span>To make our software development services walk through every part of the globe.</span>
            </li>
            <li className="flex items-start">
              <span className="mr-2 text-red-500">🎯</span>
              <span>To be the leading One-Stop Enterprise for providing complete technology solutions.</span>
            </li>
            <li className="flex items-start">
              <span className="mr-2 text-red-500">🎯</span>
              <span>To deliver our innovations globally.</span>
            </li>
            <li className="flex items-start">
              <span className="mr-2 text-red-500">🎯</span>
              <span>To help clients “imagine and invent” their future through an innovation-led approach.</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
