import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const testimonials = [
  {
    id: 1,
    name: "Sarah Johnson",
    role: "CEO at TechStart",
    content: "LKProfessionals transformed our online presence completely. Their web development expertise brought our vision to life, resulting in a 40% increase in user engagement.",
    image: "🤩"
  },
  {
    id: 2,
    name: "Michael Chen",
    role: "Marketing Director",
    content: "Working with LKProfessionals was a game-changer for our company. They delivered a website that exceeded our expectations and truly represented our brand.",
    image: "🎉"
  },
  {
    id: 3,
    name: "Emily Roberts",
    role: "Small Business Owner",
    content: "The team at LKProfessionals is incredibly skilled and professional. They created a user-friendly website that has significantly boosted my online sales.",
    image: "📈"
  },
  {
    id: 4,
    name: "Daniel Smith",
    role: "Startup Founder",
    content: "LKProfessionals helped us build a website that perfectly captures the essence of our startup. Their attention to detail and creativity were exceptional.",
    image: "🚀"
  },
  {
    id: 5,
    name: "Jennifer Lee",
    role: "E-commerce Manager",
    content: "Our e-commerce website, developed by LKProfessionals, has driven a significant increase in online sales. Their expertise in user experience and SEO was invaluable.",
    image: "🛒"
  },
  {
    id: 6,
    name: "David Wilson",
    role: "Freelance Designer",
    content: "Collaborating with LKProfessionals was a pleasure. They seamlessly integrated my design concepts into a functional and visually stunning website.",
    image: "🎨"
  },
  {
    id: 7,
    name: "Olivia Baker",
    role: "Non-profit Organization",
    content: "LKProfessionals created a website that effectively communicates our mission and inspires donations. Their commitment to social causes was evident in their work.",
    image: "❤️"
  },
  {
    id: 8,
    name: "Thomas Green",
    role: "Small Business Owner",
    content: "I was impressed by LKProfessionals' ability to understand my business goals and translate them into a website that drives results. Their customer service was top-notch.",
    image: "👍"
  },
  {
    id: 9,
    name: "Sophia Brown",
    role: "Marketing Consultant",
    content: "LKProfessionals delivered a website that not only looks great but also performs well in search engine rankings. Their SEO expertise is impressive.",
    image: "🏆"
  },
  {
    id: 10,
    name: "Ethan White",
    role: "Startup Founder",
    content: "LKProfessionals helped us launch our startup with a website that made a strong first impression. Their attention to detail and professionalism were outstanding.",
    image: "✨"
  },
  {
    id: 11,
    name: "Ava Jones",
    role: "E-commerce Manager",
    content: "Our e-commerce website, developed by LKProfessionals, has significantly improved our customer conversion rate. Their expertise in user experience and design was crucial.",
    image: "🤑"
  },
  {
    id: 12,
    name: "Liam Carter",
    role: "Freelance Designer",
    content: "Working with LKProfessionals was a breeze. They seamlessly integrated my design concepts into a website that exceeded my expectations.",
    image: "🌟"
  }
];

export default function TestimonialSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-black py-12 px-4">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-8 text-gray-300">What Our Clients Say</h2>
        <div className="relative">
          <div className="overflow-hidden">
            <div className="flex items-center justify-center">
              <div className="w-full max-w-2xl bg-gray-950 text-gray-300 rounded-lg shadow-lg p-8">
                <div className="flex items-center mb-4">
                  <div className="text-4xl mr-4">{testimonials[currentIndex].image}</div>
                  <div>
                    <h3 className="font-semibold text-lg">{testimonials[currentIndex].name}</h3>
                    <p className="text-gray-300">{testimonials[currentIndex].role}</p>
                  </div>
                </div>
                <p className="text-gray-300 italic">"{testimonials[currentIndex].content}"</p>
              </div>
            </div>
          </div>
          
          <button
            onClick={prevSlide}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 transition-colors"
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>
          
          <button
            onClick={nextSlide}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 transition-colors"
          >
            <ChevronRight className="w-6 h-6 text-gray-600" />
          </button>
        </div>
        
        <div className="flex justify-center mt-4">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-3 h-3 rounded-full mx-1 ${
                index === currentIndex ? 'bg-gray-200' : 'bg-gray-500'
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
