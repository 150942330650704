import React from "react";
import AboutSection from "./AboutSection";
import AgileMethodology from "./AgileMethodology";
import MissionSection from "./MissionSection";
import VisionSection from "./VisionSection";
import WhyUs from "./WhyUs";
import WeAreHiring from "./WeAreHiring";
import AboutBreadcrumb from "./AboutBreadcrumb";

const AboutUsPage = () => {
  return (
    <div>
      <AboutBreadcrumb />
      <AboutSection />
      <WhyUs />
      <VisionSection />
      <MissionSection />
      <AgileMethodology />
      <WeAreHiring />
    </div>
  );
};

export default AboutUsPage;
