import React, { useState, useEffect } from "react";
import { Search, Sun, Moon, Menu, X, ChevronDown } from "lucide-react";
import Logo from "./Logo";
import { Link, useLocation } from "react-router-dom";

const navItems = [
  { name: "Home", to: "/" },
  {
    name: "About", to: "/about-us", },
  {
    name: "Services",
    to: "/services",
    subItems: [
      {
        name: "Web Design",
        to: "/services/website-design-and-development",
      },
      {
        name: "Web Development",
        to: "/services/web-app-development",
      },
      {
        name: "Mobile App Development",
        to: "/services/mobile-application-development",
      },
      {
        name: "Development Consulting",
        to: "/services/web-development-consulting",
      },
      { name: "SEO", to: "/services/search-engine-optimization" },
      { name: "Digital Marketing", to: "/services/digital-marketing" },
      {
        name: "Maintenance & Support",
        to: "/services/maintenance-and-support",
      },
    ],
  },
  { name: "Careers", to: "/careers" },
  { name: "Contact Us", to: "/contact-us" },
];

export default function Header() {
  const [isDark, setIsDark] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setIsMobileMenuOpen(false);
    setActiveSubmenu(null);
  }, [location]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
    setActiveSubmenu(null);
  };

  const toggleSubmenu = (index) => {
    setActiveSubmenu(activeSubmenu === index ? null : index);
  };

  return (
    <header
      className={`fixed w-full transition-all duration-300 z-50 ${
        isScrolled ? "bg-gray-900/95 backdrop-blur-sm shadow-lg" : "bg-gray-900"
      }`}
    >
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <Link to="/">
            <Logo />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden lg:block">
            <div className="ml-10 flex items-baseline space-x-4">
              {navItems.map((item, index) => (
                <div key={item.name} className="relative group">
                  <Link
                    to={item.to}
                    className={`px-3 py-2 rounded-md text-sm font-medium transition-all duration-200 relative group ${
                      location.pathname === item.to
                        ? "text-white bg-gray-700"
                        : "text-gray-300 hover:text-white hover:bg-gray-700"
                    }`}
                    onMouseEnter={() =>
                      item.subItems && setActiveSubmenu(index)
                    }
                    onMouseLeave={() => setActiveSubmenu(null)}
                  >
                    {item.name}
                    {item.subItems && (
                      <ChevronDown className="inline-block ml-1 h-4 w-4" />
                    )}
                    <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-500 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200" />
                  </Link>
                  {item.subItems && (
                    <div
                      className={`absolute left-0 mt-2 w-64 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5 transition-all duration-200 ${
                        activeSubmenu === index
                          ? "opacity-100 visible transform translate-y-0"
                          : "opacity-0 invisible transform -translate-y-2"
                      }`}
                      onMouseEnter={() => setActiveSubmenu(index)}
                      onMouseLeave={() => setActiveSubmenu(null)}
                    >
                      <div className="py-1">
                        {item.subItems.map((subItem) => (
                          <Link
                            key={subItem.name}
                            to={subItem.to}
                            className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white transition-colors duration-200"
                          >
                            {subItem.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          
          {/* <div className="hidden lg:flex items-center space-x-4">
            <div className="relative group">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" />
              </div>
              <input
                className="w-full pl-10 pr-3 py-2 border border-gray-700 rounded-md bg-gray-800 text-gray-300 placeholder-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all duration-200"
                placeholder="Search"
                type="search"
              />
            </div>

            <button
              onClick={() => setIsDark(!isDark)}
              className="p-2 rounded-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200"
              aria-label="Toggle theme"
            >
              {isDark ? (
                <Moon className="h-5 w-5 text-gray-300 hover:text-white transition-colors duration-200" />
              ) : (
                <Sun className="h-5 w-5 text-gray-300 hover:text-white transition-colors duration-200" />
              )}
            </button>

            
            <Link
              to="/contact-us"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 transform hover:scale-105"
            >
              Get Started
            </Link>
          </div> */}

          {/* Mobile menu button */}
          <div className="lg:hidden">
            <button
              onClick={toggleMobileMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white transition-colors duration-200"
              aria-expanded={isMobileMenuOpen}
            >
              <span className="sr-only">Open main menu</span>
              {isMobileMenuOpen ? (
                <X className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        <div
          className={`lg:hidden transition-all duration-300 pt-4 ease-in-out ${
            isMobileMenuOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
          } overflow-hidden`}
        >
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 ">
            {navItems.map((item, index) => (
              <div key={item.name}>
                <div
                  className="flex items-center justify-between"
                  onClick={() => item.subItems && toggleSubmenu(index)}
                >
                  <Link
                    to={item.to}
                    className={`block px-3 py-2 rounded-md text-base font-medium transition-colors duration-200 ${
                      location.pathname === item.to
                        ? "text-white bg-gray-700"
                        : "text-gray-300 hover:text-white hover:bg-gray-700"
                    }`}
                    onClick={item.subItems ? undefined : closeMobileMenu}
                  >
                    {item.name}
                  </Link>
                  {item.subItems && (
                    <ChevronDown
                      className={`h-5 w-5 text-gray-300 transition-transform duration-200 ${
                        activeSubmenu === index ? "transform rotate-180" : ""
                      }`}
                    />
                  )}
                </div>
                {item.subItems && (
                  <div
                    className={`pl-4 space-y-1 transition-all duration-200 ${
                      activeSubmenu === index
                        ? "max-h-96 opacity-100"
                        : "max-h-0 opacity-0"
                    } overflow-hidden`}
                  >
                    {item.subItems.map((subItem) => (
                      <Link
                        key={subItem.name}
                        to={subItem.to}
                        className="block px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 transition-colors duration-200"
                        onClick={closeMobileMenu}
                      >
                        {subItem.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="pt-4 pb-3 border-t border-gray-700">
            {/* <div className="flex items-center px-5">
              <button
                onClick={() => setIsDark(!isDark)}
                className="ml-auto p-2 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              >
                {isDark ? (
                  <Moon className="h-6 w-6" />
                ) : (
                  <Sun className="h-6 w-6" />
                )}
              </button>
            </div> */}
            {/* <div className="mt-3 px-2 space-y-1">
              <Link
                to="/contact-us"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 transition-colors duration-200"
                onClick={closeMobileMenu}
              >
                Get Started
              </Link>
            </div> */}
          </div>
        </div>
      </nav>
    </header>
  );
}
