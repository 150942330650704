import React, { useState, useEffect } from 'react';
import {   Monitor,   Palette,   Globe,   Search,   BarChart,   Smartphone,   ShoppingCart,   Code,   Layout,   FileText,   Cloud,   TrendingUp,   Shield,   Wrench,   Layers,   Link,   Headphones,   Tag,   Plug, Brain, BadgeDollarSign, Gauge  } from 'lucide-react';

const slides = [
  {
    title: "Web Design Excellence",
    description: "Crafting visually stunning and user-friendly websites that leave a lasting impression.",
    icon: Palette
  },
  {
    title: "Professional Development",
    description: "Building robust, scalable web applications with cutting-edge technologies.",
    icon: Monitor
  },
  {
    title: "Global Digital Solutions",
    description: "Empowering businesses worldwide with innovative web strategies.",
    icon: Globe
  },
  {
    title: "SEO Optimization",
    description: "Ensuring websites rank high on search engines with strategic SEO implementations.",
    icon: Search
  },
  {
    title: "Responsive Design",
    description: "Delivering seamless user experiences across all devices and screen sizes.",
    icon: Smartphone
  },
  {
    title: "E-commerce Solutions",
    description: "Developing secure, scalable, and feature-rich online stores.",
    icon: ShoppingCart
  },
  {
    title: "Custom Web Applications",
    description: "Creating tailor-made web applications to meet specific business needs.",
    icon: Code
  },
  {
    title: "CMS Development",
    description: "Building content management systems for easy website updates and management.",
    icon: FileText
  },
  {
    title: "UI/UX Design",
    description: "Designing intuitive and engaging interfaces for the best user experience.",
    icon: Layout
  },
  {
    title: "Mobile App Integration",
    description: "Integrating websites with mobile applications for a seamless cross-platform experience.",
    icon: Smartphone
  },
  {
    title: "Performance Optimization",
    description: "Enhancing website speed, reducing load times, and improving overall performance.",
    icon: Gauge
  },
  {
    title: "Security & Compliance",
    description: "Implementing top-notch security measures and ensuring compliance with web standards.",
    icon: Shield
  },
  {
    title: "Cloud Hosting Solutions",
    description: "Offering scalable cloud hosting services to ensure smooth website performance.",
    icon: Cloud
  },
  {
    title: "Content Strategy",
    description: "Crafting and optimizing content to engage users and drive conversions.",
    icon: FileText
  },
  {
    title: "Website Maintenance",
    description: "Providing ongoing maintenance and updates to keep websites running smoothly.",
    icon: Wrench
  },
  {
    title: "Custom Plugin Development",
    description: "Building custom plugins to enhance website functionality and features.",
    icon: Plug
  },
  {
    title: "Third-Party Integrations",
    description: "Seamlessly integrating third-party services like payment gateways and CRMs.",
    icon: Layers
  },
  {
    title: "Analytics & Insights",
    description: "Tracking and analyzing user data to optimize website performance.",
    icon: BarChart
  },
  {
    title: "Technical Support",
    description: "Offering 24/7 support to resolve technical issues quickly and efficiently.",
    icon: Headphones
  },
  {
    title: "API Development",
    description: "Building APIs that facilitate smooth data exchange between platforms.",
    icon: Link
  },
  {
    title: "Digital Marketing Integration",
    description: "Incorporating digital marketing tools and strategies into websites for growth.",
    icon: BadgeDollarSign
  },
  {
    title: "Brand Identity Design",
    description: "Creating websites that reflect the brand’s values and identity.",
    icon: Tag
  },
  {
    title: "Multilingual Websites",
    description: "Developing websites that support multiple languages for global reach.",
    icon: Globe
  },
  {
    title: "AI & Automation",
    description: "Integrating AI-driven solutions and automation to enhance website capabilities.",
    icon: Brain
  },
  {
    title: "Conversion Rate Optimization",
    description: "Optimizing websites to increase lead generation and improve conversion rates.",
    icon: TrendingUp
  }
];


export default function HeroSection() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  // Function to get a random slide index
  const getRandomSlide = () => {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * slides.length);
    } while (randomIndex === currentSlide); // Avoid repeating the same slide
    return randomIndex;
  };

  useEffect(() => {
    if (!isHovered) {
      const timer = setInterval(() => {
        setCurrentSlide(getRandomSlide);
      }, 3000); // Make it faster by setting the time to 3 seconds (3000 ms)
      return () => clearInterval(timer);
    }
  }, [isHovered, currentSlide]);

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setMousePosition({
      x: (e.clientX - rect.left) / rect.width,
      y: (e.clientY - rect.top) / rect.height,
    });
  };

  return (
    <div 
      className="relative bg-black text-white min-h-screen flex flex-col justify-between overflow-hidden"
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Top animated elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-900/20 to-purple-900/20" />
        
        {/* Grid lines */}
        <div className="absolute inset-0 opacity-20">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute bg-blue-500/30 transform -rotate-45"
              style={{
                left: `${i * 10}%`,
                top: '-100%',
                width: '1px',
                height: '300%',
                animation: `slideDown 20s ${i * 0.5}s infinite linear`,
              }}
            />
          ))}
        </div>

        {/* Floating orbs */}
        <div className="absolute inset-0">
          {[...Array(15)].map((_, i) => (
            <div
              key={i}
              className="absolute rounded-full mix-blend-screen animate-float"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 60}%`,
                width: `${Math.random() * 300 + 50}px`,
                height: `${Math.random() * 300 + 50}px`,
                background: `radial-gradient(circle at center, 
                  rgba(59, 130, 246, ${Math.random() * 0.2 + 0.1}) 0%, 
                  rgba(59, 130, 246, 0) 70%)`,
                animation: `float ${Math.random() * 10 + 10}s infinite`,
                animationDelay: `${Math.random() * 5}s`,
                transform: `translate(
                  ${(mousePosition.x - 0.5) * 30}px,
                  ${(mousePosition.y - 0.5) * 30}px
                )`,
                transition: 'transform 0.3s ease-out',
              }}
            />
          ))}
        </div>

        {/* Particle effect */}
        <div className="absolute inset-0">
          {[...Array(50)].map((_, i) => (
            <div
              key={i}
              className="absolute bg-blue-400 rounded-full"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 60}%`,
                width: '2px',
                height: '2px',
                animation: `particle ${Math.random() * 20 + 10}s infinite`,
                animationDelay: `${Math.random() * 5}s`,
              }}
            />
          ))}
        </div>
      </div>
      
      {/* Content section */}
      <div className="container mx-auto px-4 z-10 flex-1 flex flex-col justify-start mt-44">
        <div className="relative">
          {slides.map((slide, index) => {
            const Icon = slide.icon;
            return (
              <div
                key={index}
                className={`transition-all duration-700 absolute w-full ${
                  index === currentSlide 
                    ? 'opacity-100 transform translate-y-0 scale-100' 
                    : index < currentSlide 
                      ? 'opacity-0 transform translate-y-full scale-95' 
                      : 'opacity-0 transform -translate-y-full scale-95'
                }`}
              >
                <div className="flex flex-col items-center text-center">
                  <div className="relative mb-6 group">
                    <div className="absolute inset-0 bg-blue-500 rounded-full blur-xl opacity-20 group-hover:opacity-30 transition-opacity duration-300" />
                    <Icon className="w-20 h-20 text-blue-400 relative z-10 transform group-hover:scale-110 transition-transform duration-300" />
                  </div>
                  <h1 className="text-5xl md:text-7xl font-bold mb-6 tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-200">
                    {slide.title}
                  </h1>
                  <p className="text-xl md:text-2xl text-gray-300 max-w-2xl mx-auto mb-10 leading-relaxed">
                    {slide.description}
                  </p>
                  <a href="https://wa.link/d7hs70" target="_blank"><button className="group bg-gradient-to-r from-blue-500 to-blue-600 text-white font-semibold py-4 px-10 rounded-full transition-all duration-300 hover:from-blue-600 hover:to-blue-700 hover:shadow-lg hover:shadow-blue-500/50 transform hover:-translate-y-1">
                    Get Started
                    <span className="ml-2 inline-block transition-transform duration-300 group-hover:translate-x-1">→</span>
                  </button></a>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Slide indicators */}
      <div className="container mx-auto px-4 z-10 pb-8">
        <div className="flex justify-center space-x-4">
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentSlide(index)}
              className={`w-16 h-1 rounded-full transition-all duration-300 ${
                index === currentSlide 
                  ? 'bg-blue-500 scale-x-100' 
                  : 'bg-white/30 hover:bg-white/50 scale-x-75 hover:scale-x-100'
              }`}
            />
          ))}
        </div>
      </div>

      <style jsx global>{`
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-20px); }
        }
        @keyframes slideDown {
          0% { transform: translateY(-100%) rotate(-45deg); }
          100% { transform: translateY(100%) rotate(-45deg); }
        }
        @keyframes particle {
          0%, 100% { transform: translate(0, 0); opacity: 0; }
          50% { transform: translate(${Math.random() * 100 - 50}px, ${Math.random() * 100 - 50}px); opacity: 1; }
        }
        .animate-float {
          animation: float 10s infinite ease-in-out;
        }
      `}</style>
    </div>
  );
}
