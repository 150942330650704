import React from "react";
import ContactDetails from "./ContactDetails";
import ContactForm from "./ContactForm";
import FAQ from "../home/FAQ";
import SocialMedia from "./SocialMedia";
import ContactBreadcrumb from "./ContactBreadcrumb";

const ContactUsPage = () => {
  return (
    <div>
      <ContactBreadcrumb />

      <div className="max-w-6xl mx-auto px-4 py-8">
        <div className="grid md:grid-cols-2 gap-8 mb-12">
          <ContactForm />

          <div>
            <ContactDetails />
            <SocialMedia />
          </div>
        </div>

        <FAQ />
      </div>
    </div>
  );
};

export default ContactUsPage;
