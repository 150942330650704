import React from 'react';
import { FacebookIcon, TwitterIcon, InstagramIcon, LinkedinIcon} from 'lucide-react';

const SocialMedia = () => {
  const socialMedia = [
    { icon: <FacebookIcon size={20} />, name: 'Facebook', link: 'https://www.facebook.com/lkprofessionals/' },
    { icon: <TwitterIcon size={20} />, name: 'Twitter', link: '#' },
    { icon: <InstagramIcon size={20} />, name: 'Instagram', link: '#' },
    { icon: <LinkedinIcon size={20} />, name: 'LinkedIn', link: '#' }
  ];

  return (
    <div className="bg-gray-950 text-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Follow Us</h2>
      <div className="grid grid-cols-2 gap-4">
        {socialMedia.map((platform, index) => (
          <a target="_blank"
            key={index}
            href={platform.link}
            className="flex items-center p-2 hover:bg-gray-50 rounded-md transition-colors"
          >
            {platform.icon}
            <span className="ml-2">{platform.name}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialMedia;