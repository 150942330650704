import React from 'react';
import { FaClock, FaBusinessTime, FaLaptopCode, FaDraftingCompass, FaGlobe, FaUserGraduate } from 'react-icons/fa';

const WhyUs = () => {
  const features = [
    {
      icon: <FaClock size={50} className="text-blue-400" />,
      title: 'Quick Delivery',
      description: 'Our team of designers, developers, QAs & project managers ensure quality and timely delivery of products.'
    },
    {
      icon: <FaBusinessTime size={50} className="text-blue-400" />,
      title: 'Business Attitude',
      description: 'Our business attitude helps us in building an unbreakable bond with our clients. We can foresee what is better for them.'
    },
    {
      icon: <FaLaptopCode size={50} className="text-blue-400" />,
      title: 'Technical Ability',
      description: 'Our technical experts carry in-depth knowledge of all emerging latest technologies to provide innovative solutions.'
    },
    {
      icon: <FaDraftingCompass size={50} className="text-blue-400" />,
      title: 'World Class Designs',
      description: 'With years of experience in designing interactive products for UI/UX, we can build an attractive & user-friendly design.'
    },
    {
      icon: <FaGlobe size={50} className="text-blue-400" />,
      title: 'Offshore Pricing',
      description: 'We offer offshore pricing for our clients around the world. It eases the business and helps all of us grow together.'
    },
    {
      icon: <FaUserGraduate size={50} className="text-blue-400" />,
      title: 'Skill Development',
      description: 'We believe in growing with learning. Our blended learning program means you can learn in your best suitable way.'
    }
  ];

  return (
    <section className="py-16 bg-gray-950">
      <div className="container mx-auto px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-xl text-blue-500 font-bold">WHERE CAN WE HELP YOU</h2>
          <h1 className="text-4xl font-extrabold text-gray-100 mb-12">
            Why <span className="text-blue-500">LKProfessionals?</span>
          </h1>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-black p-8 rounded-lg shadow-lg transition-transform transform hover:scale-105">
              <div className="flex justify-center mb-6">
                {feature.icon}
              </div>
              <h3 className="text-2xl font-semibold text-gray-200 text-center mb-4">{feature.title}</h3>
              <p className="text-gray-300 text-center">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
