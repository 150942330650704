import React from 'react';
import ServiceOverviewSection from './ServiceOverviewSection';
import ServiceBenefitsSection from './ServiceBenefitsSection';
import ServiceProcessSection from './ServiceProcessSection';
import ServiceTestimonialsSection from './ServiceTestimonialsSection';
import WebDesignBreadcrumb from './WebDesignBreadcrumb';

const WebDesign = () => {
  return (
    <div className="bg-gray-950 text-white">
      <WebDesignBreadcrumb/>
      <ServiceOverviewSection />
      <ServiceBenefitsSection />
      <ServiceProcessSection />
      <ServiceTestimonialsSection />
    </div>
  );
};

export default WebDesign;
