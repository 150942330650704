import React from 'react';

const ServiceOverviewSection = () => {
  return (
    <section className="py-16 bg-gray-950 text-center text-white">
      <h1 className="text-4xl font-bold mb-4">Keeping Your Online Presence Running Smoothly</h1>
      <p className="max-w-3xl mx-auto text-lg text-gray-400">
      Our dedicated support team ensures your website and applications are always up-to-date and performing optimally. We offer ongoing maintenance, security updates, and technical support.
      </p>
    </section>
  );
};

export default ServiceOverviewSection;
