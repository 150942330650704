import React from 'react';
import { motion } from 'framer-motion';

const SnowAnimation = () => {
  // Create an array of snowflakes
  const snowflakes = Array.from({ length: 50 }).map((_, i) => {
    const randomDirection = Math.random() < 0.5 ? 1 : -1; // Randomly choose direction
    return {
      id: i,
      size: Math.random() * 4 + 1, // Random size between 1 and 5
      x: Math.random() * 100, // Random horizontal position
      delay: Math.random() * 10, // Random delay for staggered animation
      direction: randomDirection // Randomize the left-right movement
    };
  });

  return (
    <div className="fixed inset-0 pointer-events-none overflow-hidden">
      {snowflakes.map((flake) => (
        <motion.div
          key={flake.id}
          className="absolute bg-neutral-700 rounded-full opacity-70"
          style={{
            width: flake.size,
            height: flake.size,
            left: `${flake.x}%`,
            top: `-${flake.size}px`
          }}
          animate={{
            y: ['0%', '100vh'], // Animate vertically down
            x: [
              `${flake.x}%`,
              `${flake.x + flake.direction * (Math.random() * 10)}%`, // Random horizontal movement
              `${flake.x + flake.direction * (Math.random() * 10)}%`,
              `${flake.x}%`
            ]
          }}
          transition={{
            duration: 10 + Math.random() * 5, // Random duration between 10 and 15 seconds
            repeat: Infinity,
            delay: flake.delay,
            ease: "linear",
            times: [0, 0.33, 0.66, 1]
          }}
        />
      ))}
    </div>
  );
};

export default SnowAnimation;
