import React from 'react';
import ServiceOverviewSection from './ServiceOverviewSection';
import ServiceBenefitsSection from './ServiceBenefitsSection';
import ServiceProcessSection from './ServiceProcessSection';
import ServiceTestimonialsSection from './ServiceTestimonialsSection';
import WebDevelopmentBreadcrumb from './WebDevelopmentBreadcrumb';

const WebDevelopment = () => {
  return (
    <div className="bg-gray-950 text-white">
      <WebDevelopmentBreadcrumb/>
      <ServiceOverviewSection />
      <ServiceBenefitsSection />
      <ServiceProcessSection />
      <ServiceTestimonialsSection />
    </div>
  );
};

export default WebDevelopment;
