import React from 'react';

const ServiceOverviewSection = () => {
  return (
    <section className="py-16 bg-gray-950 text-center text-white">
      <h1 className="text-4xl font-bold mb-4">Building Powerful Online Experiences</h1>
      <p className="max-w-3xl mx-auto text-lg text-gray-400">
      We develop robust, high-performance websites that deliver exceptional user experiences. Our skilled developers leverage the latest technologies to create websites that are both visually appealing and functionally superior.
      </p>
    </section>
  );
};

export default ServiceOverviewSection;
