import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';

const FAQ = () => {
  const [openFaq, setOpenFaq] = useState(null);

  const faqs = [
    {
      question: 'What services do you offer?',
      answer: 'We are open Monday through Friday, 9:00 AM to 5:00 PM EST.'
    },
    {
      question: 'How long does a typical web development project take?',
      answer: 'We typically respond to inquiries within 24-48 business hours.'
    },
    {
      question: 'Do you offer maintenance and support after project completion?',
      answer: 'Yes, we ship to most countries worldwide. Shipping rates vary by location.'
    },
    {
      question: 'Can you help with SEO (Search Engine Optimization)?',
      answer: 'We accept all major credit cards, PayPal, and bank transfers.'
    },
    {
      question: 'Can you help with SEO (Search Engine Optimization)?',
      answer: 'We accept all major credit cards, PayPal, and bank transfers.'
    },
    {
      question: 'Can you help with SEO (Search Engine Optimization)?',
      answer: 'We accept all major credit cards, PayPal, and bank transfers.'
    },
    {
      question: 'Can you help with SEO (Search Engine Optimization)?',
      answer: 'We accept all major credit cards, PayPal, and bank transfers.'
    },
    {
      question: 'Can you help with SEO (Search Engine Optimization)?',
      answer: 'We accept all major credit cards, PayPal, and bank transfers.'
    }
  ];

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  // Split faqs into two arrays for two columns
  const midPoint = Math.ceil(faqs.length / 2);
  const leftColumnFaqs = faqs.slice(0, midPoint);
  const rightColumnFaqs = faqs.slice(midPoint);

  const FaqColumn = ({ faqs, startIndex }) => (
    <div className="space-y-4">
      {faqs.map((faq, index) => {
        const actualIndex = startIndex + index;
        return (
          <div 
            key={actualIndex} 
            className="bg-gray-900 text-white rounded-lg shadow-md transition-shadow hover:border-gray-100 hover:border-2"
          >
            <button
              className="w-full p-4 text-left focus:outline-none"
              onClick={() => toggleFaq(actualIndex)}
              aria-expanded={openFaq === actualIndex}
            >
              <div className="flex justify-between items-center">
                <h3 className="font-medium text-lg pr-4">{faq.question}</h3>
                <div className="flex-shrink-0">
                  {openFaq === actualIndex ? (
                    <ChevronUpIcon size={20} />
                  ) : (
                    <ChevronDownIcon size={20} />
                  )}
                </div>
              </div>
            </button>
            <div 
              className={`grid overflow-hidden transition-all duration-300 ease-in-out ${
                openFaq === actualIndex ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
              }`}
            >
              <div className="overflow-hidden">
                <p className="px-4 pb-4 text-gray-600">
                  {faq.answer}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="bg-gray-950 text-white p-4 sm:p-6 rounded-lg">
      <h2 className="text-xl sm:text-2xl font-semibold mb-4 sm:mb-6 text-center">
        Frequently Asked Questions
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
        <FaqColumn faqs={leftColumnFaqs} startIndex={0} />
        <FaqColumn faqs={rightColumnFaqs} startIndex={midPoint} />
      </div>
    </div>
  );
};

export default FAQ;