import React from 'react';
import aboutLKProfessionals from '../../assets/about-lkprofessionals.svg'

export default function AboutSection() {
  return (
    <section className="bg-gray-950 text-white py-16 px-4 md:px-8 lg:px-16">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="space-y-6">
            <h2 className="text-2xl font-medium text-gray-400">About Us</h2>
            <h3 className="text-4xl md:text-5xl font-bold leading-tight">
              We are innovators driving digital transformation in tech
            </h3>
            <p className="text-lg text-gray-300">
              Our team combines strategic thinking with cutting-edge technology to create 
              impactful solutions for forward-thinking businesses ready to revolutionize 
              their digital presence.
            </p>
            <button className="px-6 py-3 border-2 border-white rounded-full 
              hover:bg-white hover:text-gray-950 transition-colors">
              Learn More
            </button>
          </div>
          <div className="hidden lg:block">
            <img src={aboutLKProfessionals} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}