import React from 'react';
import VisionPng from '../../assets/Vision.png';

export default function VisionSection() {
  return (
    <section className="bg-black py-16">
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        {/* Left Side - Vision Text */}
        <div className="text-white md:w-1/2 px-4 md:px-8">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-blue-500">
            OUR VISION
          </h2>
          <p className="text-lg mb-6">
            Our vision is to deliver unsurpassed IT solutions to our potential prospects for their exact requirements. Being one of the pioneers in the Software Industry, all our services together deliver and uphold premium values & integrity.
          </p>

          {/* Vision Goals List */}
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="mr-2 text-red-500">🎯</span>
              <span>To deliver exact outcomes to our clientele needs through our innovative technology solutions.</span>
            </li>
            <li className="flex items-start">
              <span className="mr-2 text-red-500">🎯</span>
              <span>When our clients grow in their business, we believe we are growing a step further.</span>
            </li>
            <li className="flex items-start">
              <span className="mr-2 text-red-500">🎯</span>
              <span>To spread ourselves globally and help our customers with our high-quality solutions.</span>
            </li>
            <li className="flex items-start">
              <span className="mr-2 text-red-500">🎯</span>
              <span>To be the partner-of-choice for the best-in-class software service provider.</span>
            </li>
            <li className="flex items-start">
              <span className="mr-2 text-red-500">🎯</span>
              <span>To make our every solution & product inspired by people.</span>
            </li>
          </ul>
        </div>

        {/* Right Side - Image */}
        <div className="md:w-1/2 mt-12 md:mt-0 px-4 md:px-8">
          {/* Replace the img src with your actual image */}
          <img src={VisionPng} alt="Vision Illustration" className="mx-auto max-w-xs" />
        </div>
      </div>
    </section>
  );
}
