import { motion } from 'framer-motion';

const ServicesHeader = () => {
  return (
    <motion.div
      className="text-center py-12"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      <h1 className="text-4xl sm:text-6xl font-bold text-white mb-4 bg-gradient-to-r from-blue-400 to-purple-600 text-transparent bg-clip-text">
        Our Services
      </h1>
      <p className="text-neutral-400 text-sm sm:text-lg max-w-3xl mx-auto">
        We offer a wide range of web development services tailored to meet your business needs.
      </p>
    </motion.div>
  );
};

export default ServicesHeader;
