import React from "react";
import CareersBreadcrumb from "./CareersBreadcrumb";
import OpenPositions from "./OpenPositions";
import WhyJoinUs from "./WhyJoinUs";

const CareersPage = () => {
  return (
    <div>
      <CareersBreadcrumb />
      <WhyJoinUs />
      <OpenPositions />
    </div>
  );
};

export default CareersPage;
