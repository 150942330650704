import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import Bootstrap5 from '../../assets/technology/Bootstrap5.png' 
import CSS3 from '../../assets/technology/CSS3.png'
import Django from '../../assets/technology/Rest-Api.png'//
import JavaScript from '../../assets/technology/JavaScript.png'
import NodeJS from '../../assets/technology/NodeJS.png'
import NuxtJs from '../../assets/technology/NuxtJs.png'
import PostgreSQL from '../../assets/technology/PostgreSQL.png'
import Python from '../../assets/technology/Python.png'
import ReactJS from '../../assets/technology/ReactJS.png'
import ReactNative from '../../assets/technology/ReactNative.png'
import Redux from '../../assets/technology/Redux.png';
import RestApi from '../../assets/technology/Rest-Api.png';
import SAPERP from '../../assets/technology/SAPERP.png'
import TailwindCSS from '../../assets/technology/Rest-Api.png'//
import Wordpress from '../../assets/technology/Wordpress.png';
import VueJs from '../../assets/technology/VueJs.png'
import Woocommerce from '../../assets/technology/Woocommerce.png'

// Review items
const technologies = [
  { name: "Bootstrap5", image: Bootstrap5 },
  { name: "CSS3", image: CSS3 },
  { name: "Django", image: Django },
  { name: "JavaScript", image: JavaScript },
  { name: "NodeJS", image: NodeJS },
  { name: "NuxtJs", image: NuxtJs },
  { name: "PostgreSQL", image: PostgreSQL },
  { name: "Python", image: Python },
  { name: "ReactJS", image: ReactJS },
  { name: "ReactNative", image: ReactNative },
  { name: "Redux", image: Redux },
  { name: "RestApi", image: RestApi },
  { name: "SAPERP", image: SAPERP },
  { name: "TailwindCSS", image: TailwindCSS },
  { name: "Wordpress", image: Wordpress },
  { name: "VueJs", image: VueJs },
  { name: "Woocommerce", image: Woocommerce },
];

// Function to shuffle the technologies array
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const TechnologyCard = ({ review }) => (
  <motion.div
    className="bg-neutral-900 rounded-xl p-4 sm:p-6 min-w-[250px] sm:min-w-[300px] md:w-[350px] lg:w-[400px] mx-2 sm:mx-4 backdrop-blur-sm bg-opacity-50 border border-neutral-800 hover:border-blue-500 transition-all duration-300"
    whileHover={{ 
      scale: 1.02,
      boxShadow: "0 0 20px rgba(59, 130, 246, 0.3)"
    }}
  >
    <div className="flex items-center mb-3 sm:mb-4">
      <img 
        src={review.image} 
        alt={`${review.name} logo`} 
        className="w-10 h-10 sm:w-12 sm:h-12 rounded-full mr-3"
      />
      <p className="text-white font-semibold text-base sm:text-lg">{review.name}</p>
    </div>
  </motion.div>
);

const InfiniteSlider = ({ items, direction = 'left', speed = 20 }) => {
  const [duplicatedItems, setDuplicatedItems] = useState([]);
  const [sliderWidth, setSliderWidth] = useState(0);

  useEffect(() => {
    setDuplicatedItems([...items, ...items]);
    
    const updateWidth = () => {
      const cardWidth = window.innerWidth < 640 ? 250 : 
                        window.innerWidth < 768 ? 300 : 
                        window.innerWidth < 1024 ? 350 : 400;
      const totalWidth = cardWidth * items.length;
      setSliderWidth(totalWidth);
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [items]);

  return (
    <div className="overflow-hidden relative w-full h-[150px] sm:h-[150px] md:h-[150px]"> {/* Removed spacing between sliders */}
      <motion.div
        className="flex"
        animate={{
          x: direction === 'left' ? [-sliderWidth, 0] : [0, -sliderWidth]
        }}
        transition={{
          duration: speed,
          ease: "linear",
          repeat: Infinity,
        }}
      >
        {duplicatedItems.map((review, index) => (
          <TechnologyCard key={index} review={review} />
        ))}
      </motion.div>
    </div>
  );
};

const Technology = () => {
  const [shuffledtechnologies, setShuffledtechnologies] = useState([]);

  useEffect(() => {
    setShuffledtechnologies(shuffleArray([...technologies]));
  }, []);

  const halfLength = Math.ceil(shuffledtechnologies.length / 2);
  const firstRow = shuffledtechnologies.slice(0, halfLength);
  const secondRow = shuffledtechnologies.slice(halfLength);

  return (
    <div className="bg-black text-white py-4 sm:py-8 lg:py-12 px-4 sm:px-6 lg:px-8 overflow-hidden">
      <motion.div 
        className="text-center mb-8 sm:mb-12 lg:mb-16"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-4xl font-bold text-center mb-8 text-gray-300">Technology we use</h2>

        {/* <p className="text-neutral-400 text-sm sm:text-base lg:text-lg max-w-xl sm:max-w-2xl mx-auto px-4">
        </p> */}
      </motion.div>

      <div className="relative">
        <div className="absolute left-0 w-16 sm:w-32 h-full bg-gradient-to-r from-black to-transparent z-10"></div>
        <div className="absolute right-0 w-16 sm:w-32 h-full bg-gradient-to-l from-black to-transparent z-10"></div>
        
        {/* First Row */}
        <InfiniteSlider items={firstRow} direction="left" speed={40} />

        {/* Second Row */}
        <InfiniteSlider items={secondRow} direction="right" speed={35} />
      </div>
    </div>
  );
};

export default Technology;
