import React from 'react';
import ServiceOverviewSection from './ServiceOverviewSection';
import ServiceBenefitsSection from './ServiceBenefitsSection';
import ServiceProcessSection from './ServiceProcessSection';
import ServiceTestimonialsSection from './ServiceTestimonialsSection';
import DigitalMarketingBreadcrumb from './DigitalMarketingBreadcrumb';

const DigitalMarketing = () => {
  return (
    <div className="bg-gray-950 text-white">
      <DigitalMarketingBreadcrumb />
      <ServiceOverviewSection />
      <ServiceBenefitsSection />
      <ServiceProcessSection />
      <ServiceTestimonialsSection />
    </div>
  );
};

export default DigitalMarketing;
