import React from 'react';

const ServiceOverviewSection = () => {
  return (
    <section className="py-16 bg-gray-950 text-center text-white">
      <h1 className="text-4xl font-bold mb-4">Expand Your Online Reach</h1>
      <p className="max-w-3xl mx-auto text-lg text-gray-400">
      Our comprehensive digital marketing services help you connect with your target audience and drive conversions. We utilize a range of strategies to increase brand awareness and generate leads.
      </p>
    </section>
  );
};

export default ServiceOverviewSection;
