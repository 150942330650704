import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(
        'service_f5arexk',    // Replace with your EmailJS Service ID
        'template_p3pogq9',    // Replace with your EmailJS Template ID
        formData,
        'cL4fpj9ND-qc1VmcT'      // Replace with your EmailJS Public Key
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setSubmitStatus('success');
          setFormData({
            name: '',
            email: '',
            subject: '',
            message: ''
          });
        },
        (error) => {
          console.log('FAILED...', error);
          setSubmitStatus('error');
        }
      );
  };

  return (
    <div className="bg-gray-950 text-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Send us a message</h2>
      <form onSubmit={sendEmail}>
        <div className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium mb-1">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md bg-gray-950 text-white"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium mb-1">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md bg-gray-950 text-white"
              required
            />
          </div>
          <div>
            <label htmlFor="subject" className="block text-sm font-medium mb-1">
              Subject
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md bg-gray-950 text-white"
              required
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-sm font-medium mb-1">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              rows="4"
              value={formData.message}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md bg-gray-950 text-white"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-900 text-white py-2 px-4 rounded-md hover:bg-blue-950 transition-colors"
          >
            Send Message
          </button>
        </div>
      </form>
      {submitStatus === 'success' && (
        <div className="mt-4 p-4 bg-green-50 text-green-700 rounded-md">
          Thank you for your message! We'll get back to you soon.
        </div>
      )}
      {submitStatus === 'error' && (
        <div className="mt-4 p-4 bg-red-50 text-red-700 rounded-md">
          Oops! Something went wrong. Please try again.
        </div>
      )}
    </div>
  );
};

export default ContactForm;
