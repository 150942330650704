import React from "react";
import HeroSection from "./Hero";
import Technology from "./Technology";
import Testimonials from "./Testimonials";
import ProcessTabs from "./WebsiteProcessTabs";
import FAQ from "./FAQ";
import IndustryGrid from "./IndustryGrid";

const HomePage = () => {
  return (
    <div>
      <HeroSection />
      <Technology />
      <Testimonials />
      <ProcessTabs />
      <IndustryGrid />
      <FAQ />
    </div>
  );
};

export default HomePage;
