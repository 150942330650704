import React from 'react';

const ServiceOverviewSection = () => {
  return (
    <section className="py-16 bg-gray-950 text-center text-white">
      <h1 className="text-4xl font-bold mb-4">Boost Your Online Visibility</h1>
      <p className="max-w-3xl mx-auto text-lg text-gray-400">Our SEO experts employ proven strategies to improve your website's search engine rankings. We optimize your website's content, structure, and technical aspects to drive organic traffic.</p>
    </section>
  );
};

export default ServiceOverviewSection;
