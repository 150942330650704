import React from 'react';
import ServiceOverviewSection from './ServiceOverviewSection';
import ServiceBenefitsSection from './ServiceBenefitsSection';
import ServiceProcessSection from './ServiceProcessSection';
import ServiceTestimonialsSection from './ServiceTestimonialsSection';
import WebConsultingBreadcrumb from './WebConsultingBreadcrumb';

const WebConsulting = () => {
  return (
    <div className="bg-gray-950 text-white">
      <WebConsultingBreadcrumb/>
      <ServiceOverviewSection />
      <ServiceBenefitsSection />
      <ServiceProcessSection />
      <ServiceTestimonialsSection />
    </div>
  );
};

export default WebConsulting;
