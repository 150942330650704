import React from 'react';

const ServiceOverviewSection = () => {
  return (
    <section className="py-16 bg-gray-950 text-center text-white">
      <h1 className="text-4xl font-bold mb-4">Crafting Digital Masterpieces</h1>
      <p className="max-w-3xl mx-auto text-lg text-gray-400">
      Our expert designers create stunning, responsive websites that captivate your audience. From sleek and minimalist to bold and dynamic, we tailor designs to your brand's unique identity.
      </p>
    </section>
  );
};

export default ServiceOverviewSection;
