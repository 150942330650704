import { motion } from 'framer-motion';

const CallToAction = () => {
  return (
    <motion.div
      className="text-center py-12 bg-gradient-to-r from-black   via-gray-400 to-black rounded-xl mt-12 shadow-md hover:shadow-lg transition-shadow duration-300"
      whileHover={{ scale: 1.05 }}
    >
      <h2 className="text-white text-3xl font-semibold mb-4">Ready to take your business online?</h2>
      <button className="bg-black text-white px-6 py-3 rounded-lg font-semibold hover:bg-white hover:text-black transition-all duration-300">
        Get in Touch
      </button>
    </motion.div>
  );
};

export default CallToAction;
