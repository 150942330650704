import React from 'react';

const ServiceOverviewSection = () => {
  return (
    <section className="py-16 bg-gray-950 text-center text-white">
      <h1 className="text-4xl font-bold mb-4">Taking Your Business to the Mobile World</h1>
      <p className="max-w-3xl mx-auto text-lg text-gray-400">
      Our mobile app development services help you reach your target audience on the go. We build native and hybrid apps for iOS and Android platforms that are user-friendly and feature-rich.
      </p>
    </section>
  );
};

export default ServiceOverviewSection;
