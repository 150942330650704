import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/app/Header";
import Footer from "./components/app/Footer";
import BackToTop from "./components/app/BackToTop";
import HomePage from "./components/home/HomePage";
import AboutUs from "./components/about/AboutUsPage";
import Services from "./components/service/ServicesPage";
import Portfolio from "./components/portfolio/PortfolioPage";
import ContactUs from "./components/contact/ContactUsPage";
import SnowAnimation from "./components/app/SnowAnimation";
import CareersPage from "./components/about/careers/CareersPage";
import PrivacyPolicy from "./components/legal/PrivacyPolicy";
import TermsOfService from "./components/legal/TermsOfService";
import CookiePolicy from "./components/legal/CookiePolicy";
import DigitalMarketing from "./components/service/digitalMarketing/DigitalMarketing";
import Maintenance from "./components/service/maintenance/Maintenance";
import MobileDevelopment from "./components/service/mobileDevelopment/MobileDevelopment";
import SEO from "./components/service/sEO/SEO";
import WebConsulting from "./components/service/webConsulting/WebConsulting";
import WebDesign from "./components/service/webDesign/WebDesign";
import WebDevelopment from "./components/service/webDevelopment/WebDevelopment";

const App = () => {
  return (
    <Router>
      <div className="bg-black">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/legal/terms-of-service" element={<TermsOfService />} />
          <Route path="/legal/cookie-policy" element={<CookiePolicy />} />
          <Route
            path="/services/digital-marketing"
            element={<DigitalMarketing />}
          />
          <Route
            path="/services/maintenance-and-support"
            element={<Maintenance />}
          />
          <Route
            path="/services/mobile-application-development"
            element={<MobileDevelopment />}
          />
          <Route
            path="/services/search-engine-optimization"
            element={<SEO />}
          />
          <Route
            path="/services/web-development-consulting"
            element={<WebConsulting />}
          />
          <Route
            path="/services/website-design-and-development"
            element={<WebDesign />}
          />
          <Route
            path="/services/web-app-development"
            element={<WebDevelopment />}
          />
        </Routes>
        <Footer />
        <BackToTop />
        <SnowAnimation />
      </div>
    </Router>
  );
};

export default App;
