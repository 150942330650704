import React from "react";

const industries = [
  { title: "Education", color: "bg-blue-500", icon: "🎓" },
  { title: "Health Care", color: "bg-purple-500", icon: "💉" },
  { title: "Entertainment", color: "bg-gray-800", icon: "🎥" },
  { title: "Financial Services", color: "bg-red-500", icon: "🏦" },
  { title: "Accounting", color: "bg-blue-400", icon: "💼" },
  { title: "E-Commerce", color: "bg-green-500", icon: "🛒" },
  { title: "Start-Ups", color: "bg-purple-600", icon: "🚀" },
  { title: "Garments", color: "bg-teal-400", icon: "👕" },
  { title: "Travel", color: "bg-green-400", icon: "🌍" },
  { title: "Logistics", color: "bg-orange-500", icon: "🚚" },
  { title: "Restaurant", color: "bg-red-400", icon: "🍲" },
  { title: "Industries", color: "bg-green-800", icon: "🏭" },
  { title: "Real Estate", color: "bg-brown-700", icon: "🏡" },
  { title: "IT Companies", color: "bg-pink-500", icon: "💻" },
  { title: "Sports", color: "bg-blue-700", icon: "🏀" },
  { title: "Community", color: "bg-yellow-500", icon: "👥" },
];

const IndustryGrid = () => {
  return (
    <div className="bg-gray-950 py-12">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-white text-4xl font-bold text-center mb-8">
          Industries Empowered by Our Website Designing
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
          {industries.map((industry, index) => (
            <div
              key={index}
              className={`flex flex-col items-center justify-center p-4 rounded-lg transform transition-all duration-300 ease-in-out hover:-translate-y-2 cursor-pointer ${industry.color}`}
            >
              <div className="text-5xl mb-4">{industry.icon}</div>
              <p className="text-white font-bold text-lg">{industry.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IndustryGrid;
