import React from 'react';

const WeAreHiring = () => {
  return (
    <div className="bg-black text-white py-16 px-8">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-6">We Are Hiring!</h2>
        <p className="text-lg mb-8">
          Join our passionate and dynamic team at <span className="text-blue-400">LKProfessionals</span>! We are looking for talented professionals who are eager to create innovative websites and provide world-class web development services. If you have the skills, creativity, and dedication, we want to hear from you.
        </p>
        <p className="text-lg mb-8">
          Whether you're a developer, designer, or digital marketing expert, we have exciting opportunities that can help you grow in your career.
        </p>
        <a 
          href="/careers" 
          className="inline-block bg-blue-500 text-black py-3 px-8 font-semibold rounded-lg shadow-lg hover:bg-blue-400 transition duration-300"
        >
          View Open Positions
        </a>
      </div>
    </div>
  );
};

export default WeAreHiring;
