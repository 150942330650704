import React, { useState } from 'react';
import { Users, Layers, Palette, Monitor, TestTube, Rocket, Wrench } from 'lucide-react';

const processSteps = [
  {
    id: 1,
    title: 'Requirement',
    subtitle: '(Gathering)',
    Icon: Users,
    color: 'text-blue-500',
    description: `Taking the time to understand and know your client's needs in detail is commendable for your company. Conducting in-depth research into the industry to develop an attractive, engaging, and effective business website requires a strategic approach. Exceptional results require a commitment to a well-defined and structublue website development process. Your methodology reflects a holistic, client-centblue approach, incorporating industry best practices and cutting-edge technologies. Integrating these elements will allow you to meet client's needs immediately and position your company as a reliable long-term partner. Building websites systematically and client-focused can contribute significantly to their long-term success and satisfaction.`
  },
  {
    id: 2,
    title: 'Planning',
    subtitle: '(Idea)',
    Icon: Layers,
    description: "Collaboration with clients from the outset is a commendable practice. Establishing a successful partnership begins with understanding their unique requirements, target audience, and business objectives. The detailed collaboration allows you to develop a comprehensive strategy and plan tailoblue to the client’s requirements. A personalised and strategic approach ensures that the final product aligns with the client’s vision and contributes to building a strong working relationship. You set the stage for effective communication and a clear understanding of expectations by emphasizing this initial phase of collaboration. The result is tailor-made solutions that meet the specific needs of your clients."
  },
  {
    id: 3,
    title: 'Design',
    subtitle: '(Process)',
    Icon: Palette,
    description: "Collaboration with clients from the outset is a commendable practice. Establishing a successful partnership begins with understanding their unique requirements, target audience, and business objectives. The detailed collaboration allows you to develop a comprehensive strategy and plan tailoblue to the client’s requirements. A personalised and strategic approach ensures that the final product aligns with the client’s vision and contributes to building a strong working relationship. You set the stage for effective communication and a clear understanding of expectations by emphasizing this initial phase of collaboration. The result is tailor-made solutions that meet the specific needs of your clients."
  },
  {
    id: 4,
    title: 'Development',
    subtitle: '(Update)',
    Icon: Monitor,
    description: "Businesses need an online presence in today’s digital landscape. It’s a pleasure to hear that your search for website development experts in Bangalore has ended with your team. Designing a website that displays, sells, and creates positive first impressions demands analytical and creative skills. Your website development experts aim to deliver just that. Your comprehensive approach, from brainstorming and planning to designing, coding, content writing, testing, and final launch, with 100% assistance throughout the development process, ensures a seamless and client-centric experience. The emphasis on making website development easy and time-saving for clients is a strategic move, and the commitment to keeping track of tasks, meeting deadlines, and establishing milestones underscores your dedication to delivering impactful and timely results."
  },
  {
    id: 5,
    title: 'Error',
    subtitle: '(Testing)',
    Icon: TestTube,
    description: "The fact that your company emphasizes extensive testing at every stage of development to ensure flawless website functionality is commendable. Your commitment to a thorough quality assurance process ensures a bug-free website. Testing is an integral and critical part of the development process, as it assists in identifying and resolving potential issues. This commitment to delivering a bug-free website therefore leads to a higher quality end product and a positive user experience, ensuring that your website performs optimally and meets the expectations of your clients and visitors. A well-tested website is more reliable and effective, contributing to the success of your clients’ online marketing campaigns."
  },
  {
    id: 6,
    title: 'Launch',
    subtitle: '(Deployment)',
    Icon: Rocket,
    description: "Thank you for recognizing the importance of innovative approaches and constant analysis in website development. Observing that the launch of the business website marks the beginning of the success of the brand and website is insightful. Understanding that the website development process does not end with launch, but involves ongoing testing and updating processes, demonstrates a commitment to continuous improvement. Assuring your esteemed customers of a strong support system, starting with the design phase and extending to ongoing maintenance, is a valuable service. The result is that websites are launched successfully and can adapt, evolve, and remain productive over time. They have sustained success in the digital landscape as a result of this."
  },
  {
    id: 7,
    title: 'Maintenance',
    subtitle: '(After Service)',
    Icon: Wrench,
    description: "We are pleased to learn that LKProfessionals’s partnership extends beyond its initial collaboration. Your clients need to maintain continuous support and maintenance services. The websites’ long-term success depends on their ability to remain secure, up-to-date, and optimized. The management of a website requires ongoing support and maintenance. As part of their duties, they address potential issues, implement updates, and ensure the health and performance of the website. You can maintain a secure and efficient online presence by following this approach and fostering a lasting relationship with your clients."
  },
];

export default function WebsiteProcessTabs() {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <div className="bg-black text-white min-h-screen p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-4">
          Experience a Seamless Website Development Process
        </h1>
        <div className="w-24 h-1 bg-blue-500 mx-auto mb-6"></div>
        <p className="text-center text-gray-400 mb-16">
          Unlock your online presence's potential with our streamlined website development process.
        </p>

        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 gap-4 mb-12">
          {processSteps.map((step, index) => {
            const Icon = step.Icon;
            return (
              <div
                key={step.id}
                className={`cursor-pointer transition-all duration-300 ${
                  activeStep === index ? 'bg-blue-500/10' : 'hover:bg-gray-800'
                } rounded-lg p-4`}
                onClick={() => setActiveStep(index)}
              >
                <div className={`flex flex-col items-center transition-transform duration-300 transform ${
                  activeStep === index ? 'scale-110' : ''
                }`}>
                  <div className={`mb-2 ${activeStep === index ? 'text-blue-500' : 'text-gray-400'}`}>
                    <Icon size={24} />
                  </div>
                  <h3 className="font-semibold text-center">{step.title}</h3>
                  {step.subtitle && (
                    <p className="text-sm text-gray-400 text-center">{step.subtitle}</p>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <div className="bg-gray-900 rounded-lg p-8 transition-all duration-500">
          <h2 className="text-2xl font-bold mb-4">
            {processSteps[activeStep].title}
            {processSteps[activeStep].subtitle && 
              <span className="text-blue-500"> {processSteps[activeStep].subtitle}</span>
            }
          </h2>
          <p className="text-gray-400 leading-relaxed">
            {processSteps[activeStep].description}
          </p>
        </div>
      </div>
    </div>
  );
}