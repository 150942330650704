import React from "react";
import { TEInput } from "tw-elements-react";
import { motion } from "framer-motion";
import LogoFooter from "./LogoFooter";
import { Link } from "react-router-dom";

export default function EnhancedFooter() {
  const socialLinks = [
    {
      link: "https://www.facebook.com/lkprofessionals",
      icon: "M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z",
      name: "Facebook",
    },
    {
      link: "https://x.com/lkprofess",
      icon: "M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z",
      name: "Twitter",
    },
    {
      link: "https://www.instagram.com/lkprofesionals/",
      icon: "M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z",
      name: "Instagram",
    },
    {
      link: "https://www.linkedin.com/company/lkprofessionals/",
      icon: "M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z",
      name: "LinkedIn",
    },
  ];

  const quickLinks = [
    {
      title: "Services",
      links: [
        {
          name: "Website Design",
          to: "/services/website-design-and-development",
        },
        { name: "Web Development", to: "/services/web-app-development" },
        {
          name: "Mobile App Development",
          to: "/services/mobile-application-development",
        },
        {
          name: "Development Consulting",
          to: "/services/web-development-consulting",
        },
        { name: "SEO", to: "/services/search-engine-opmization" },
        { name: "Digital Marketing", to: "/services/digital-marketing" },
        {
          name: "Maintenance and Support",
          to: "/services/maintenance-and-support",
        },
      ],
    },
    {
      title: "Company",
      links: [
        { name: "About Us", to: "/about-us" },
        { name: "Our Team", to: "/our-team" },
        { name: "Careers", to: "/careers" },
        { name: "Contact Us", to: "/contact-us" },
      ],
    } /* 
    {
      title: "Resources",
      links: [
        { name: "Blog", to: "/blog" },
        { name: "Case Studies", to: "/case-studies" },
        { name: "Whitepapers", to: "/whitepapers" },
        { name: "FAQs", to: "/faqs" },
      ],
    }, */,
    {
      title: "Legal",
      links: [
        { name: "Privacy Policy", to: "/legal/privacy-policy" },
        { name: "Terms of Service", to: "/legal/terms-of-service" },
        { name: "Cookie Policy", to: "/legal/cookie-policy" },
        /* { name: "Sitemap", to: "/sitemap" }, */
      ],
    },
  ];

  const handleLinkClick = (event, to) => {
    event.preventDefault();
    // Navigate to the new page
    window.location.href = to;
    // After a short delay to allow for navigation, scroll to the top
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  };

  return (
    <footer className="bg-black text-white">
      <div className="container px-6 pt-6 mx-auto items-center justify-center w-full flex">
        <LogoFooter />
      </div>
      <div className="container px-6 pt-6 mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-8 flex flex-wrap justify-center gap-8"
        >
          {socialLinks.map((link, index) => (
            <motion.a
              key={index}
              href={link.link}
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className="group relative h-10 w-10 flex items-center justify-center rounded-full border-2 border-white transition-all duration-300 hover:bg-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 transition-colors duration-300 group-hover:text-neutral-900"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d={link.icon} />
              </svg>
              <span className="absolute -bottom-8 scale-0 transition-all duration-300 group-hover:scale-100">
                {link.name}
              </span>
            </motion.a>
          ))}
        </motion.div>

        {/* <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="mb-8"
        >
          <form className="mx-auto max-w-md">
            <div className="flex flex-col items-center gap-4 md:flex-row">
              <div className="w-full md:w-2/3">
                <TEInput
                  theme={{
                    label:
                      "pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate text-white transition-all duration-200 ease-out peer-focus:scale-[0.8] peer-focus:text-primary motion-reduce:transition-none",
                  }}
                  type="email"
                  label="Subscribe to our newsletter"
                />
              </div>
              
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="w-full md:w-auto px-6 py-2 bg-white text-neutral-900 rounded transition-colors duration-300 hover:bg-neutral-200 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
              >
                Subscribe
              </motion.button>
            </div>
          </form>
        </motion.div> */}

        <div className="bg-neutral-950">
          <hr className="p-4 text-center border-t border-white/10" />

          <div className="grid gap-8 sm: grid-cols-1 md:grid-cols-2 lg:grid-cols-4 text-center ">
            {quickLinks.map((section, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 * index, duration: 0.5 }}
                className="mb-6"
              >
                <h5 className="mb-4 text-2xl font-bold">{section.title}</h5>
                <ul className="space-y-2 sm:text-center md:text-center lg:text-left xl:text-left 2xl:text:left lg:pl-100 xl:pl-12 2xl:pl-20">
                  {section.links.map((link, linkIndex) => (
                    <li key={linkIndex}>
                      <a
                        href={link.to}
                        onClick={(e) => handleLinkClick(e, link.to)}
                        className="hover:text-neutral-400 transition-colors duration-300 cursor-pointer"
                      >
                        {link.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
            <div className="container px-6 pt-6 mx-auto">
              <h5 className="mb-4 text-2xl font-bold">Contacts</h5>
              <ul className="space-y-2 sm:text-center md:text-center lg:text-left xl:text-left 2xl:text:left lg:pl-100 xl:pl-12 2xl:pl-20">
                <li>
                  <a href="tel:+94761234321" className="hover:text-neutral-400 transition-colors duration-300 cursor-pointer">
                    Call Us
                  </a>
                </li>
                <li>
                  <a href="https://wa.link/d7hs70" target="_blank" className="hover:text-neutral-400 transition-colors duration-300 cursor-pointer">
                    Whatsapp
                  </a>
                </li>
                <li>
                  <a href="mailto:info@lkprofessionals.com" className="hover:text-neutral-400 transition-colors duration-300 cursor-pointer">
                    Email
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

<br />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
        className="p-8 text-lg text-center border-t border-white/10"
      >
        © 2024
        <Link
          to="/"
          className="mx-1 text-white hover:text-neutral-400 transition-colors duration-300"
        >
          LKProfessionals
        </Link>
        All rights reserved.
      </motion.div>
    </footer>
  );
}
